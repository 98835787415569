import apiClient from "@/services/axios";

export default {
  postLocal(local) {
    return apiClient.post("/cadLocais/cadastrar", local);
  },

  putLocal(local) {
    return apiClient.put("/cadLocais/editar", local);
  },

  getLocaisSelecionar(id, licenca) {
    return apiClient.get(`/cadLocais/selecionar/${id}/${licenca}`);
  },

  getLocaisListar(licenca) {
    return apiClient.get(`/cadLocais/listar/${licenca}`);
  },
};
