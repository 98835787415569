<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10 lg8 xl6>
        <v-data-table
          dense
          :headers="headers"
          :items="compLocaisLista"
          class="elevation-10"
          :search="search"
          :loading="!compLocaisProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-google-maps</v-icon>
              <v-toolbar-title>Locais</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarLocalEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Local</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group mandatory active-class="primary" show-arrows>
                    <v-chip @click="listaLocLocais = 'locais'"> Locais </v-chip>
                    <v-chip @click="listaLocLocais = 'lixeira'">
                      Lixeira
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  v-if="metEdicaoLixeira()"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarLocalEdicao(item)"
                  color="blue darken-1"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  :color="metLixeiraColor(item.lixeira)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metParaLixeira(item)"
                >
                  {{ item.lixeira ? "mdi-recycle" : "mdi-trash-can" }}
                </v-icon>
              </template>
              <span>{{ item.lixeira ? "Reciclar" : "Lixeira" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
// import moment from "moment";

const { mapState, mapActions, mapGetters } =
  createNamespacedHelpers("cadLocais");

export default {
  mixins: [DatasMixin],
  name: "CadLocaisLista",
  data: () => ({
    listaLocLocais: "locais",
    search: "",
    headers: [
      { text: "ID", value: "id", align: "start" },
      {
        text: "Local",
        align: "start",
        sortable: true,
        value: "local",
      },
      {
        text: "Tipo",
        align: "start",
        sortable: true,
        value: "tipo",
      },
      {
        text: "Código",
        align: "start",
        sortable: true,
        value: "codigo",
      },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["staErro", "staLocais", "staLocalSelecionado"]),
    ...mapGetters(["getLocLocais", "getLocLixeira"]),

    compLocaisLista() {
      return this.listaLocLocais == "locais"
        ? this.getLocLocais
        : this.getLocLixeira;
      // return this.$store.state.cadLocais.staLocais;
    },
    compLocaisProgress() {
      // console.log(!this.$store.state.cadLocais.staLocais.length);
      return this.$store.state.cadLocais.staLocais;
    },
  },

  created() {
    register(this.$store);
    this.actListarLocais(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterLocais();
  },

  methods: {
    ...mapActions([
      "actListarLocais",
      "actSelecionarLocal",
      "actResetarLocal",
      "actLixeiraLocal",
    ]),

    metPermissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    metSelecionarLocalEdicao(local) {
      this.actSelecionarLocal({ local });
      this.$router.push({ name: "CadLocaisForm" });
    },

    metLixeiraColor(lixeira) {
      if (lixeira) return "orange darken-2";
      else return "red darken-1";
    },

    metParaLixeira(local) {
      this.actLixeiraLocal({
        local: local,
        licenca: this.$store.state.login.licenca,
        modificacao: this.$store.state.login.usuario.usuario,
      });
    },

    metEdicaoLixeira() {
      if (this.listaLocLocais == "lixeira") {
        return false;
      }
      return true;
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.actListarLocais(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style></style>
