import * as types from "./mutation-types";

export default {
  [types.CRIAR_LOCAL]: (state, { local }) => {
    state.staLocais.push(local);
  },
  [types.EDITAR_LOCAL]: (state, { local }) => {
    const indice = state.staLocais.findIndex((l) => l.id === local.id);
    state.staLocais.splice(indice, 1, local);
  },
  [types.DELETAR_LOCAL]: (state, { local }) => {
    const indice = state.staLocais.findIndex((l) => l.id === local.id);
    state.staLocais.splice(indice, 1);
  },
  [types.LISTAR_LOCAIS]: (state, { locais }) => {
    state.staLocais = locais;
  },
  [types.SELECIONAR_LOCAL]: (state, { local }) => {
    state.staLocalSelect = local;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};
