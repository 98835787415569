import axios from "../_services/axios";
import * as types from "./mutation-types";

export default {
  actCriarLocal: async ({ commit, dispatch }, payload) => {
    try {
      const response = await axios.postLocal(payload);
      payload.local.id = response.data.id;
      commit(types.CRIAR_LOCAL, { local: payload.local });
      var local = payload.local;
      dispatch("actSelecionarLocal", { local });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actEditarLocal: async ({ commit }, payload) => {
    try {
      const response = await axios.putLocal(payload);
      commit(types.EDITAR_LOCAL, {
        local: JSON.parse(response.config.data)["local"],
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actSelecionarLocal: ({ commit }, payload) => {
    commit(types.SELECIONAR_LOCAL, payload);
  },

  actListarLocais: async ({ commit }, licenca) => {
    try {
      const response = await axios.getLocaisListar(licenca);
      commit(types.LISTAR_LOCAIS, {
        locais: response.data.response,
      });
    } catch (erro) {
      commit(types.SETAR_ERRO, { erro });
    }
  },

  actLixeiraLocal: async ({ dispatch }, payload) => {
    const local = Object.assign({}, payload.local);
    local.lixeira = !local.lixeira;
    local.modificacao = payload.modificacao;
    dispatch("actEditarLocal", {
      local: local,
      licenca: payload.licenca,
    });
  },

  actResetarLocal: ({ commit }) => {
    commit(types.SELECIONAR_LOCAL, { local: undefined });
  },

  actResetarErro: ({ commit }) => {
    commit(types.SETAR_ERRO, { undefined });
  },
};
